<template>
  <div data-app>
    <div class="page-title pageheading" style="height: 50px">
      <h3 class="titlename">
        <i class="menu-icon flaticon-clock-2"></i
        ><span class="ml-3 text-color">Import</span>
      </h3>
    </div>
    <div class="row p-10 justify-space-around" width="100">
      <v-card
        class="offset-1 col-4 d-flex flex-column justify-center align-center p-5"
        style="gap: 2rem"
      >
        <v-icon x-large>perm_identity</v-icon>
        <h1 class="text-center">Import Clients</h1>
        <span class="text-center" style="display: none">
          <button class="btn btn-success">
            <vue-excel-xlsx
              :columns="clientSample"
              :file-name="'Client Sample'"
              :file-type="'xlsx'"
              ref="DownloadClientSample"
              :data="[{ item: '' }]"
            >
              <small> Download Sample File </small>
            </vue-excel-xlsx>
          </button>
        </span>
        <span class="text-center">
          <input
            type="file"
            ref="ClientFileImport"
            @input="onFileChangeClient"
            style="display: none"
          />
        </span>
        <v-btn
          @click="ImportClients"
          depressed
          dark
          class="btn btn-success mr-3"
        >
          <span v-if="UpdateClients"> Import Clients </span>
          <v-progress-circular
            v-else
            indeterminate
            color="error"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          @click="DownloadClientSample"
          depressed
          dark
          class="btn btn-success mr-3"
        >
          Download Sample file
        </v-btn>
      </v-card>
      <v-card
        class="col-4 offset-2 d-flex flex-column justify-center align-center p-5"
        style="gap: 2rem"
      >
        <v-icon x-large>supervisor_account</v-icon>
        <h1 class="text-center">Import Caregivers</h1>
        <span class="text-center" style="display: none">
          <button class="btn btn-success">
            <vue-excel-xlsx
              :columns="caregiverSample"
              ref="DownloadCaregiver"
              :file-name="'Caregiver Sample'"
              :file-type="'xlsx'"
              :data="[{ item: '' }]"
            >
              <small> Download Sample File </small>
            </vue-excel-xlsx>
          </button>
        </span>
        <span class="text-center">
          <input
            v-if="uploaded"
            type="file"
            ref="ImportCaregivers"
            @change="onFileChange"
            style="display: none"
          />
          <!-- <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular> -->
        </span>
        <v-btn
          @click="ImportCaregivers"
          depressed
          dark
          class="btn btn-success mr-3"
        >
          <span v-if="uploaded"> Import Caregivers </span>
          <v-progress-circular
            v-else
            indeterminate
            color="error"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          @click="DownloadCaregiverSample"
          depressed
          dark
          class="btn btn-success mr-3"
        >
          Download Sample File
        </v-btn>
        <!-- <v-btn
          class="btn btn-success mr-3"
          dark
          style="width: 50%; align-self: center"
        >
          Import
        </v-btn> -->
      </v-card>
      <v-card
        class="col-4 offset-1 mt-5 d-flex flex-column justify-center align-center p-5"
        style="gap: 2rem"
      >
        <v-icon x-large>text_snippet</v-icon>
        <h1 class="text-center">Import Authorizations</h1>
        <span class="text-center" style="display: none">
          <button class="btn btn-success">
            <vue-excel-xlsx
              :columns="authorizationSample"
              ref="DownloadAuthorization"
              :file-name="'Authorization Sample'"
              :file-type="'xlsx'"
              :data="[{ item: '' }]"
            >
              <small> Download Sample File </small>
            </vue-excel-xlsx>
          </button>
        </span>
        <span class="text-center">
          <input
            v-if="uploaded"
            type="file"
            ref="ImportAuthorization"
            @change="onFileChangeAuth"
            style="display: none"
          />
          <!-- <v-progress-circular
            v-else
            indeterminate
            color="primary"
          ></v-progress-circular> -->
        </span>
        <v-btn
          @click="ImportAuthorization"
          depressed
          dark
          class="btn btn-success mr-3"
        >
          <span v-if="UpdateAuth"> Import Authorizations </span>
          <v-progress-circular
            v-else
            indeterminate
            color="error"
          ></v-progress-circular>
        </v-btn>
        <v-btn
          @click="DownloadAuthorizationSample"
          depressed
          dark
          class="btn btn-success mr-3"
        >
          Download Sample File
        </v-btn>
        <!-- <v-btn
          class="btn btn-success mr-3"
          dark
          style="width: 50%; align-self: center"
        >
          Import
        </v-btn> -->
      </v-card>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
export default {
  data() {
    return {
      UpdateClients: true,
      uploaded: true,
      UpdateAuth: true,
      Clients: [],
      items1: [],
      items2: [],
      errorImport: [],
      caregiverSample: [
        {
          label: "Last Name",
          field: "",
        },
        {
          label: "First Name",
          field: "",
        },
        {
          label: "Gender",
          field: "",
        },
        {
          label: "Status Type",
          field: "",
        },
        {
          label: "Employee ID",
          field: "",
        },
        {
          label: "Address",
          field: "",
        },
        {
          label: "City",
          field: "",
        },
        {
          label: "State",
          field: "",
        },
        {
          label: "Zip",
          field: "",
        },
        {
          label: "Home Phone",
          field: "",
        },
        {
          label: "Cell Number",
          field: "",
        },
        {
          label: "DOB",
          field: "",
        },
        {
          label: "Hire Date",
          field: "",
        },
        {
          label: "Last 4 Digits SSN",
          field: "",
        },
        {
          label: "Longitude",
          field: "",
        },
        {
          label: "Latitude",
          field: "",
        },
      ],
      authorizationSample: [
        {
          label: "UCI",
          field: "",
        },
        {
          label: "Vendor",
          field: "",
        },
        {
          label: "Payors",
          field: "",
        },
        {
          label: "Service",
          field: "",
        },
        {
          label: "Status",
          field: "",
        },
        {
          label: "Auth #",
          field: "",
        },
        {
          label: "Start Date",
          field: "",
        },
        {
          label: "End Date",
          field: "",
        },
        {
          label: "Roll Over",
          field: "",
        },

        {
          label: "Type",
          field: "",
        },
        {
          label: "Hours",
          field: "",
        },
        {
          label: "Term Date",
          field: "",
        },
        {
          label: "Usage Notes",
          field: "",
        },
      ],
      clientSample: [
        {
          label: "Last Name",
          field: "",
        },
        {
          label: "First Name",
          field: "",
        },
        {
          label: "Initial Start",
          field: "",
        },
        {
          label: "Regional Center",
          field: "",
        },
        {
          label: "UCI",
          field: "",
        },
        {
          label: "Address",
          field: "",
        },
        {
          label: "Address 2",
          field: "",
        },
        {
          label: "City",
          field: "",
        },
        {
          label: "State",
          field: "",
        },
        {
          label: "Zip",
          field: "",
        },
        {
          label: "Date of Birth",
          field: "",
        },
        {
          label: "Status",
          field: "",
        },
        {
          label: "Email",
          field: "",
        },
        {
          label: "Home Phone",
          field: "",
        },
        {
          label: "Work Phone",
          field: "",
        },
        {
          label: "Cell Phone",
          field: "",
        },
        {
          label: "Language",
          field: "",
        },
        {
          label: "Diagnosis",
          field: "",
        },
        {
          label: "Longitude",
          field: "",
        },
        {
          label: "Latitude",
          field: "",
        },
      ],
    };
  },
  methods: {
    ExportErrors(errors) {
      
      if (errors.length > 0) {
        errors.concat(this.errorImport);
      } else {
        errors = this.errorImport;
      } 
      const doc = new jsPDF();
      var data = [];
      if (errors.length > 0) {
        errors.forEach((t) => {
          var row = [];
          row.push(t.index);
          row.push(t.error);
          data.push(row);
        });

        // Or use javascript directly:
        doc.autoTable({
          head: [["Index", "Error"]],
          body: data,
        });

        doc.save("ImportErrors.pdf");
      }
    },
    ImportClients() {
      this.$refs.ClientFileImport.click();
    },
    ImportCaregivers() {
      this.$refs.ImportCaregivers.click();
    },
    ImportAuthorization() {
      this.$refs.ImportAuthorization.click();
    },
    DownloadAuthorizationSample() {
      this.$refs.DownloadAuthorization.$el.click();
    },
    DownloadCaregiverSample() {
      console.log(this.$refs.DownloadCaregiver.$el.click());
    },
    DownloadClientSample() {
      console.log(this.$refs.DownloadClientSample.$el.click());
    },
    async onFileChange(event) {
      this.items1 = [];
      this.uploaded = false;
      this.errorImport = [];
      var CorrectHeaderSq = true;
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      await readXlsxFile(xlsxfile).then((rows) => {
        for (var i = 0; i < this.caregiverSample.length; i++) {
          if (rows[0][i] == null) {
            CorrectHeaderSq = false;
            break;
          } else if (
            rows[0][i].toLowerCase() !=
            this.caregiverSample[i].label.toLowerCase()
          ) {
            CorrectHeaderSq = false;
            break;
          }
        }
        if (!CorrectHeaderSq) {
          this.uploaded = true;
          Swal.fire({
            title: "",
            text: "Header Sequence not correct.",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          }).then(() => {
            this.DownloadCaregiverSample();
          });
        } else {
          rows.forEach(async (x, i) => {
            if (i > 0) {
              var obj = {};
              Object.assign(obj, {
                index: (i + 1).toString(),
                lastName:
                  x[0] != null && x[0] != undefined ? x[0].toString() : "",
                firstName:
                  x[1] != null && x[1] != undefined ? x[1].toString() : "",
                gender:
                  x[2] != null && x[2] != undefined
                    ? x[2].toString()
                    : "Female",
                statusType:
                  x[3] != null && x[3] != undefined ? x[3].toString() : "",
                employeeId:
                  x[4] != null && x[4] != undefined ? x[4].toString() : "",
                address:
                  x[5] != null && x[5] != undefined ? x[5].toString() : "",
                city: x[6] != null && x[6] != undefined ? x[6].toString() : "",
                state: x[7] != null && x[7] != undefined ? x[7].toString() : "",
                zip: x[8] != null && x[8] != undefined ? x[8].toString() : "",
                homePhone:
                  x[9] != null && x[9] != undefined ? x[9].toString() : "",
                cellPhone:
                  x[10] != null && x[10] != undefined ? x[10].toString() : "",
                dob:
                  x[11] != null && x[11] != undefined
                    ? new Date(x[11])
                    : undefined,
                hireDate:
                  x[12] != null && x[12] != undefined
                    ? new Date(x[12])
                    : undefined,
                ssn:
                  x[13] != null && x[13] != undefined ? x[13].toString() : "",
                longitude:
                  x[14] != null && x[14] != undefined ? x[14].toString() : "",
                latitude:
                  x[15] != null && x[15] != undefined ? x[15].toString() : "",
              });
              var invalidDates = false;
              var errorMessage = "";

              if (obj.dob == undefined) {
                invalidDates = true;
                errorMessage += "Date of birth is empty or invalid,";
              }
              // if (obj.hireDate == undefined) {
              //   invalidDates = true;
              //   errorMessage += "Hire Date is empty or invalid,";
              // }
              if (!invalidDates) {
                this.items1.push(obj);
              } else {
                var error = {
                  index: obj.index,
                  error: errorMessage,
                };
                this.errorImport.push(error);
              }
            }
          });
        }
      });
      if (CorrectHeaderSq) {
        await this.$store
          .dispatch("saveBulkData", this.items1)
          .then((response) => {
            if (response.status) {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "success",
                confirmButtonClass: "btn btn-success",
              }).then(() => {
                if (response.data != null && response.data != undefined) {
                  this.ExportErrors(response.data);
                } else {
                  this.ExportErrors([]);
                }
              });
            } else {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              }).then(() => {
                if (response.data != null && response.data != undefined) {
                  this.ExportErrors(response.data);
                } else {
                  this.ExportErrors([]);
                }
              });
            }
            this.uploaded = true;
          });
      }
      this.$refs.ImportCaregivers.value = "";
    },
    async onFileChangeClient(event) {
      var CorrectHeaderSq = true;
      this.errorImport = [];
      this.items2 = [];
      this.UpdateClients = false;
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      await readXlsxFile(xlsxfile).then((rows) => {
        
        var err = []; 

        for (var i = 0; i < this.clientSample.length; i++) {
          if (rows[0][i] == null) {
            CorrectHeaderSq = false;
            break;
          } else if (
            rows[0][i].toLowerCase() != this.clientSample[i].label.toLowerCase()
          ) {
            CorrectHeaderSq = false;
            break;
          }
        }
        if (!CorrectHeaderSq) {
          this.UpdateClients = true;
          Swal.fire({
            title: "",
            text: "Header Sequence not correct.",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          }).then(() => {
            this.DownloadClientSample();
          });
        } else {
          rows.forEach(async (x, i) => {
            if (i > 0) {
              var obj = {};
              Object.assign(obj, {
                index: (i + 1).toString(),
                lastName: x[0] != null ? x[0] : "",
                firstName: x[1] != null ? x[1] : "",
                initialStart: new Date(x[2]),
                regionalCenter:
                  x[3] != null && x[3] != undefined ? x[3].toString() : "",
                uci: x[4] != null && x[4] != undefined ? x[4].toString() : "",
                address:
                  x[5] != null && x[5] != undefined ? x[5].toString() : "",
                address2:
                  x[6] != null && x[6] != undefined ? x[6].toString() : "",
                city: x[7] != null && x[7] != undefined ? x[7].toString() : "",
                state: x[8] != null && x[8] != undefined ? x[8].toString() : "",
                zip: x[9] != null && x[9] != undefined ? x[9].toString() : "",
                dob: new Date(x[10]),
                status:
                  x[11] != null && x[11] != undefined ? x[11].toString() : "",
                email:
                  x[12] != null && x[12] != undefined ? x[12].toString() : "",
                homePhone:
                  x[13] != null && x[13] != undefined ? x[13].toString() : "",
                workPhone:
                  x[14] != null && x[14] != undefined ? x[14].toString() : "",
                cellPhone:
                  x[15] != null && x[15] != undefined ? x[15].toString() : "",
                language:
                  x[16] != null && x[16] != undefined ? x[16].toString() : "",
                diagnosis:
                  x[17] != null && x[17] != undefined ? x[17].toString() : "",
                longitude:
                  x[18] != null && x[18] != undefined ? x[18].toString() : "",
                latitude:
                  x[19] != null && x[19] != undefined ? x[19].toString() : "",
              });
              var invalidDates = false;
              var errorMessage = ""; 
              if (obj.initialStart == undefined) {
                invalidDates = true;
                errorMessage += "Start of care is empty or invalid";
              }
              if (obj.dob == undefined) {
                invalidDates = true;
                errorMessage += "Date of birth is empty or invalid";
              }
              if (!invalidDates) {
                this.items2.push(obj);
              } else {
                var e = {
                  index: obj.index,
                  error: errorMessage,
                };
                this.errorImport.push(e);
              }
            }
          });
          // if (err.length > 0) {
          //   Swal.fire({
          //     title: "Please fix the following issues",
          //     text: err.join(", "),
          //     icon: "error",
          //   });
          // }
        }
      });
      if (CorrectHeaderSq) {
        await this.$store
          .dispatch("saveClientBulkData", this.items2)
          .then((response) => {
            if (response.status) {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "success",
                confirmButtonClass: "btn btn-success",
              }).then(() => {
                if (response.data != null && response.data != undefined) {
                  this.ExportErrors(response.data);
                } else {
                  this.ExportErrors([]);
                }
              });
            } else { 
              Swal.fire({
                title: "",
                text: response.message,
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              }).then(() => {
                if (response.data != null && response.data != undefined) {
                  this.ExportErrors(response.data);
                } else {
                  this.ExportErrors([]);
                }
              });
            }
            this.UpdateClients = true;
          });
      }
      this.$refs.ClientFileImport.value = "";
    },
    async onFileChangeAuth(event) {
      var CorrectHeaderSq = true;
      this.errorImport = [];
      this.items2 = [];
      this.UpdateAuth = false;
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      await readXlsxFile(xlsxfile).then((rows) => {
        
        var err = []; 
        for (var i = 0; i < this.authorizationSample.length; i++) {
          if (rows[0][i] == null) { 
            CorrectHeaderSq = false;
            break;
          } else if (
            rows[0][i].toLowerCase() !=
            this.authorizationSample[i].label.toLowerCase()
          ) {
            
            CorrectHeaderSq = false;
            break;
          }
        }
        if (!CorrectHeaderSq) { 
          this.UpdateAuth = true;
          Swal.fire({
            title: "",
            text: "Header Sequence not correct.",
            icon: "error",
            confirmButtonClass: "btn btn-danger",
          }).then(() => {
            this.DownloadAuthorizationSample();
          });
        } else {
           rows.forEach(async (x, i) => {
            if (i > 0 && x[3] != "JFS") {
              var obj = {}; 
              Object.assign(obj, {
                index: (i + 1).toString(),
                uci: x[0] != null && x[0] != undefined ? x[0].toString() : "",
                vender:
                  x[1] != null && x[1] != undefined ? x[1].toString() : "",
                payor: x[2] != null && x[2] != undefined ? x[2].toString() : "",
                service:
                  x[3] != null && x[3] != undefined ? x[3].toString() : "",
                status:
                  x[4] != null && x[4] != undefined ? x[4].toString() : "",
                authNo:
                  x[5] != null && x[5] != undefined ? x[5].toString() : "",
                startDate: new Date(
                  new Date(x[6]).setHours(new Date(x[6]).getHours() + 13)
                ),
                endDate: new Date(
                  new Date(x[7]).setHours(new Date(x[7]).getHours() + 13)
                ),
                rollOver:
                  x[8] != null && x[8] != undefined ? x[8].toString() : "",
                type: x[9] != null && x[9] != undefined ? x[9].toString() : "",
                hours:
                  x[10] != null && x[10] != undefined ? x[10].toString() : "",

                termDate:
                  x[11] != null && x[11] != undefined
                    ? new Date(
                        new Date(x[11]).setHours(
                          new Date(x[11]).getHours() + 13
                        )
                      )
                    : "",
                usageNotes:
                  x[12] != null && x[12] != undefined ? x[12].toString() : "",
              });
              var invalidDates = false;
              var errorMessage = ""; 
              if (obj.startDate == undefined) {
                invalidDates = true;
                errorMessage += "Start Date is empty or invalid";
              }
              if (obj.endDate == undefined) {
                invalidDates = true;
                errorMessage += "End Date is empty or invalid";
              }
              if (obj.termDate == undefined) {
                invalidDates = true;
                errorMessage += "Termination Date is empty or invalid";
              }
              if (!invalidDates) {
                this.items2.push(obj);
              } else {
                var e = {
                  index: obj.index,
                  error: errorMessage,
                };
                this.errorImport.push(e);
              }
            }
          });
          // if (err.length > 0) {
          //   Swal.fire({
          //     title: "Please fix the following issues",
          //     text: err.join(", "),
          //     icon: "error",
          //   });
          // }
        }
      });
      if (CorrectHeaderSq) {
        await this.$store
          .dispatch("ImportAuthorizations", this.items2)
          .then((response) => {
            if (response.status) {
              Swal.fire({
                title: "",
                text: response.message,
                icon: "success",
                confirmButtonClass: "btn btn-success",
              }).then(() => {
                if (response.data != null && response.data != undefined) {
                  this.ExportErrors(response.data);
                } else {
                  this.ExportErrors([]);
                }
              });
            } else { 
              Swal.fire({
                title: "",
                text: response.message,
                icon: "error",
                confirmButtonClass: "btn btn-danger",
              }).then(() => {
                if (response.data != null && response.data != undefined) {
                  this.ExportErrors(response.data);
                } else {
                  this.ExportErrors([]);
                }
              });
            }
            this.UpdateAuth = true;
          });
      }
      this.$refs.ImportAuthorization.value = "";
    },
  },
};
</script>

<style scoped></style>
